import axios from 'axios';
import ApiError from './apiError';

const apiUrl = process.env.REACT_APP_ENV !== 'local' ?
    `https://admin.onerbox.com/service/${process.env.REACT_APP_VERSION}/api` :
    'http://localhost:5010/api';

export default class ApiServiceHttp {

    static async defaultRequest(idToken, url, method, data) {
        try
        {
            const response = await axios.request({
                url: `${apiUrl}/${url}`,
                method: method,
                data: data,
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }
            });
            return response.data;
        }
        catch (error)
        {
            if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.","error");
            }
            throw new ApiError("Error in request.","error");
        }
    }


    // User info
    static async getUserInfo(idToken) {
        return await this.defaultRequest(idToken, 'user', 'get');
    }

    // Organizations
    static async getOrganizations(idToken) {
        return await this.defaultRequest(idToken, 'organizations', 'get');    
    }

    static async getOrganization(idToken, id) {
        return await this.defaultRequest(idToken, `organizations/${id}`, 'get');
    }

    static async saveOrganization(idToken, data) {
        return await this.defaultRequest(idToken, 'organizations', 'post', data);
    }

    static async updateOrganization(idToken, data) {
        return await this.defaultRequest(idToken, 'organizations', 'put', data);
    }

    static async deleteOrganization(idToken, data) {
        return await this.defaultRequest(idToken, `organizations/${data.id}`, 'delete');
    }

    // Companies
    static async getCompanies(idToken) {
        return await this.defaultRequest(idToken, 'companies', 'get');
    }

    static async getCompany(idToken, id) {
        return await this.defaultRequest(idToken, `companies/${id}`, 'get');
    }

    static async saveCompany(idToken, data) {
        return await this.defaultRequest(idToken, 'companies', 'post', data);
    }

    static async updateCompany(idToken, data) {
        return await this.defaultRequest(idToken, 'companies', 'put', data);
    }

    static async deleteCompany(idToken, data) {
        return await this.defaultRequest(idToken, `companies/${data.id}`, 'delete');
    }

    // Licenses
    static async getLicenses(idToken) {
        return await this.defaultRequest(idToken, 'licenses', 'get');
    }

    static async getLicense(idToken, id) {
        return await this.defaultRequest(idToken, `licenses/${id}`, 'get');
    }

    static async saveLicense(idToken, data) {
        return await this.defaultRequest(idToken, 'licenses', 'post', data);
    }

    static async updateLicense(idToken, data) {
        return await this.defaultRequest(idToken, 'licenses', 'put', data);
    }

    static async deleteLicense(idToken, data) {
        return await this.defaultRequest(idToken, `licenses/${data.id}`, 'delete');
    }
}

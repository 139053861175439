import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableContainer,Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

function LicensesTable({ items, onEdit }) {
    const { t } = useTranslation();

    return (
        <div>
            {items &&
            <TableContainer>
                <Table>
                    <colgroup>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'1%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("CompanyId")}</strong></TableCell>
                            <TableCell><strong>{t("Name")}</strong></TableCell>
                            <TableCell><strong>{t("Key")}</strong></TableCell>
                            <TableCell><strong>{t("Enabled")}</strong></TableCell>
                            <TableCell><strong>{t("Max requests/day")}</strong></TableCell>
                            <TableCell><strong>{t("Expiration")}</strong></TableCell>
                            <TableCell><strong>{t("Max vehicles")}</strong></TableCell>
                            <TableCell><strong>{t("Max destinations")}</strong></TableCell>
                            <TableCell><strong>{t("Edit")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.companyId}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell><code>{item.key}</code></TableCell>
                                <TableCell>
                                    {item.enabled && (
                                    <Chip label='yes' 
                                        style={{ backgroundColor: '#A9F78D' }} />
                                    )}
                                </TableCell>
                                <TableCell>{item.maxRequestsDay}</TableCell>
                                <TableCell>{item.expirationDate}</TableCell>
                                <TableCell>{item.maxVehicles}</TableCell>
                                <TableCell>{item.maxDestinations}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => { onEdit(item)}}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}

export default LicensesTable;
import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableContainer, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

function CompaniesTable({ items, onEdit }) {
    const { t } = useTranslation();

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

    return (
        <div>
            {items &&
            <TableContainer>
                <Table size='small' padding='small'>
                    <colgroup>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'1%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("Organization")}</strong></TableCell>
                            <TableCell><strong>{t("Id")}</strong></TableCell>
                            <TableCell><strong>{t("Key")}</strong></TableCell>
                            <TableCell><strong>{t("GDC")}</strong></TableCell>
                            <TableCell><strong>{t("SM Maps")}</strong></TableCell>
                            <TableCell><strong>{t("SM")}</strong></TableCell>
                            <TableCell><strong>{t("Licenses num")}</strong></TableCell>
                            <TableCell><strong>{t("Modules")}</strong></TableCell>
                            <TableCell><strong>{t("Details")}</strong></TableCell>
                            <TableCell><strong>{t("Logo")}</strong></TableCell>
                            <TableCell><strong>{t("Edit")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.organization}</TableCell>
                                <TableCell>{item.companyId}</TableCell>
                                <TableCell><code>{item.companyKey}</code></TableCell>
                                <TableCell padding='none'>
                                    {item.gdcEnabled && (
                                    <Chip size='small' label='yes' 
                                        style={{ backgroundColor: '#A9F78D' }} />
                                    )}
                                </TableCell>
                                <TableCell padding='none'>
                                    {item.smartDeliveryMaps && (
                                    <Chip size='small' label='yes' 
                                        style={{ backgroundColor: '#A9F78D' }} />
                                    )}
                                </TableCell>
                                <TableCell size='small'>
                                    {item.smartDelivery && (
                                    <Chip size='small' label='yes' 
                                        style={{ backgroundColor: '#A9F78D' }} />
                                    )}
                                </TableCell>
                                <TableCell padding='none'>{item.licensesNum}</TableCell>
                                <TableCell padding='none'>
                                    {item?.modules?.map((module, index) => (
                                        <Chip size='small'
                                            key={index} 
                                            label={truncateText(module, 15)}
                                            style={{ backgroundColor: 'rgb(186 255 255)', margin: '2px' }} 
                                        />
                                    ))}
                                </TableCell>
                                <TableCell padding='none'>
                                    {item?.details?.map((detail, index) => (
                                        <Chip size='small'
                                            key={index} 
                                            label={truncateText(detail, 15)} 
                                            style={{ backgroundColor: 'rgb(252 255 186)', margin: '2px' }} 
                                        />
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {item.logoData && (
                                        <img src={`data:${item.logoMimeType};base64,${item.logoData}`} alt="Company Logo" style={{ maxWidth: '50px' }} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => { onEdit(item)}}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}

export default CompaniesTable;
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, IconButton, FormHelperText } from '@mui/material';
import ApiService from '../../../services/apiService';
import AppSnackbar from '../../AppSnackbar';
import { UserContext } from '../../../contexts/UserProvider';
import DeleteButton from '../../ConfirmButton';

function LicenseForm({ open, onClose, onAccept, editItem }) {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [item, setItem] = useState(null);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [saving, setSaving] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [errors, setErrors] = useState({ companyId: '', name: '', key: '', expirationDate: '' });

    useEffect(() => {
        fetchCompanies();
      }, []); 

    const fetchCompanies = async () => {
        try {
            const response = await ApiService.getCompanies(userInfo?.idToken);
            const companyIds = response.map(org => org.companyId);
            companyIds.sort((a, b) => { return a.localeCompare(b); });
            setCompanies(companyIds);
        } catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
        }
    };

    useEffect(() => {
        resetForm();
        if (editItem) {
            setItem(editItem);
            setIsNew(false);
        }
    }, [editItem]);

    const validateForm = () => {
        const newErrors = {
            companyId: '',
            name: '',
            key: '',
            expirationDate: '',
        };
    
        if (!item?.companyId) {
            newErrors.companyId = 'Company ID is required';
        }

        if (!item?.name) {
            newErrors.name = 'Name is required';
        }

        if (!item?.key) {
            newErrors.key = 'Key is required';
        }       
        
        if (!item?.expirationDate) {
            newErrors.expirationDate = 'Expiration Date is required';
        }

        if (item.expirationDate && !/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(item.expirationDate)) {
            newErrors.expirationDate = 'Expiration Date is not in correct format';
        }
   
        setErrors(newErrors);
    
        return !newErrors.companyId && 
                !newErrors.name && 
                !newErrors.key &&
                !newErrors.expirationDate;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        setSaving(true);
        try {
            if (isNew) {
                await ApiService.saveLicense(userInfo?.idToken, item);
                setSnackbarInfo({message: t('License created.'), severity: 'success'});
            } else {
                await ApiService.updateLicense(userInfo?.idToken, item);
                setSnackbarInfo({message: t('License updated.'), severity: 'success'});
            }
            onAccept(item);
            handleClose();
        }
        catch(error)
        {
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
            setSaving(false);
        }
    };

    const handleDelete = async () => {
        setSaving(true);
        try {
            await ApiService.deleteLicense(userInfo.idToken, item);
            onAccept(null);
            handleClose();
            setSnackbarInfo({message: t('Deleted successfully.'), severity: 'success'});
        } 
        catch (error) {
            setSnackbarInfo({message: t(error.message), severity: error.severity});
            setSaving(false);
        }
    }

    const handleClose = () => {
        resetForm();
        onClose();
    }

    const resetForm = () => {
        const today = new Date();
        today.setMonth(today.getMonth() + 1);
        const expirationDate = today.toISOString().split('T')[0].replace(/-/g, '/');
        setItem({ id: 0, expirationDate, enabled: true, maxRequestsDay: 1000, key: generateGUID() });
        setSaving(false);
        setIsNew(true);
        setErrors({ companyId: '', name: '', key: '', expirationDate: '' });
    }

    const generateGUID = () => {
        const s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
                .toUpperCase();
        };
        return `${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}-${s4()}`;
    };

    return (
    <>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit License</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal" error={!!errors.companyId}>
                    <InputLabel>Company Id</InputLabel>
                    <Select
                        value={item?.companyId ?? ''}
                        onChange={(e) => setItem({ ...item, companyId: e.target.value })}
                    >
                        {companies.map((c) => (
                            <MenuItem key={c} value={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.companyId && <FormHelperText>{errors.companyId}</FormHelperText>}
                </FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    value={item?.name || ''}
                    onChange={(e) => setItem({...item, name: e.target.value})}
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    margin="dense"
                    label="Key"
                    type="text"
                    fullWidth
                    value={item?.key || ''}
                    onChange={(e) => setItem({...item, key: e.target.value})}
                    error={!!errors.key}
                    helperText={errors.key}
                />
                <TextField
                    margin="dense"
                    label="Expiration Date"
                    type="text"
                    fullWidth
                    value={item?.expirationDate || ''}
                    onChange={(e) => setItem({...item, expirationDate: e.target.value})}
                    error={!!errors.expirationDate}
                    helperText={errors.expirationDate}
                />
                <FormControlLabel
                    control={<Checkbox checked={item?.enabled || false} onChange={(e) => setItem({ ...item, enabled: e.target.checked })} />}
                    label="Enabled"
                />
                <TextField
                    margin="normal"
                    label="Max requests/day"
                    type="number"
                    fullWidth
                    value={item?.maxRequestsDay || 0}
                    onChange={(e) => setItem({ ...item, maxRequestsDay: e.target.value })}
                />
                <TextField
                    margin="normal"
                    label="Max vehicles"
                    type="number"
                    fullWidth
                    value={item?.maxVehicles || 0}
                    onChange={(e) => setItem({ ...item, maxVehicles: e.target.value })}
                />
                <TextField
                    margin="normal"
                    label="Max destinations"
                    type="number"
                    fullWidth
                    value={item?.maxDestinations || 0}
                    onChange={(e) => setItem({ ...item, maxDestinations: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
               { !isNew && !saving && (<DeleteButton 
                    variant="contained"
                    color="error"
                    onConfirm={handleDelete}
                    msgTitle={t("Delete license")}
                    msgText={t("Are you sure you want to delete?")}>
                    {t('Delete')}
                </DeleteButton>)}
                <Button onClick={handleClose} color="primary" disabled={saving}>
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={saving}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
    </>
    );
}

export default LicenseForm;
import React from 'react';
import { List, ListItem, ListItemText, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function SidePanel({ onSelectView }) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState({ licenses: true });

    const handleClick = (section) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [section]: !prevOpen[section],
        }));
    };

    return (
        <div style={{ width: '250px', borderRight: '1px solid #ddd', height: '100vh' }}>
            <List>
                <ListItem button onClick={() => handleClick('licenses')} style={{ backgroundColor: '#f0f0f0' }}>
                    <ListItemText primary={t('Companies and Licenses')} />
                    {open['licenses'] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open['licenses']} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('organizations')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Organizations')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('companies')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Companies')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('licenses')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Licenses')} />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={() => handleClick('anotherView')} style={{ backgroundColor: '#f0f0f0' }}>
                    <ListItemText primary={t('Another View')} />
                    {open['anotherView'] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open['anotherView']} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('pickupPackages')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Subsection A')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('anotherViewSubB')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Subsection B')} />
                        </ListItem>
                    </List>
                </Collapse>
                {/* Add more sections and subsections as needed */}
            </List>
        </div>
    );
}

export default SidePanel;
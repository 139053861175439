import ApiServiceHttp from './apiServiceHttp';
import ApiServiceMock from './apiServiceMock';

export default class ApiService {

    static service = process.env.REACT_APP_ENV === 'test' ? ApiServiceMock : ApiServiceHttp;

    // User
    static async getUserInfo(idToken) {
        return await this.service.getUserInfo(idToken);
    };

    // Organizations
    static async getOrganizations(idToken) {
        return await this.service.getOrganizations(idToken);
    };

    static async getOrganization(idToken, id) {
        return await this.service.getOrganization(idToken, id);
    }

    static async saveOrganization(idToken, data) {
        return await this.service.saveOrganization(idToken, data);
    }

    static async updateOrganization(idToken, data) {
        return await this.service.updateOrganization(idToken, data);
    }

    static async deleteOrganization(idToken, data) {
        return await this.service.deleteOrganization(idToken, data);
    }

    // Companies
    static async getCompanies(idToken) {
        return await this.service.getCompanies(idToken);
    };

    static async getCompany(idToken, id) {
        return await this.service.getCompany(idToken, id);
    }

    static async saveCompany(idToken, data) {
        return await this.service.saveCompany(idToken, data);
    }

    static async updateCompany(idToken, data) {
        return await this.service.updateCompany(idToken, data);
    }

    static async deleteCompany(idToken, data) {
        return await this.service.deleteCompany(idToken, data);
    }

    // Licenses
    static async getLicenses(idToken) {
        return await this.service.getLicenses(idToken);
    };

    static async getLicense(idToken, id) {
        return await this.service.getLicense(idToken, id);
    }

    static async saveLicense(idToken, data) {
        return await this.service.saveLicense(idToken, data);
    }

    static async updateLicense(idToken, data) {
        return await this.service.updateLicense(idToken, data);
    }

    static async deleteLicense(idToken, data) {
        return await this.service.deleteLicense(idToken, data);
    }
    
}

// src/components/Dashboard.js
import React, { useState } from 'react';
import SidePanel from './SidePanel';
import { useTranslation } from 'react-i18next';
import Organizations from './licensing/organizations/Organizations';
import Companies from './licensing/companies/Companies';
import Licenses from './licensing/licenses/Licenses';

function Dashboard() {
    const { t } = useTranslation();
    const [selectedView, setSelectedView] = useState('pickupPackages');

    const renderSelectedView = () => {
        switch (selectedView) {
            case 'organizations':
                return <Organizations />;
            case 'companies':
                return <Companies />;
            case 'licenses':
                return <Licenses />;
            case 'anotherView':
                return <div>{t('Another View Content')}</div>;
            // Add more cases for other views
            default:
                return null;
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <SidePanel onSelectView={setSelectedView} />
            <div style={{ flex: 1, padding: '20px' }}>
                {renderSelectedView()}
            </div>
        </div>
    );
}

export default Dashboard;